<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: Add New Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <!-- click-not-close -->
  <vs-sidebar
    click-not-close
    position-right
    parent="body"
    default-index="1"
    color="primary"
    class="add-new-data-sidebar items-no-padding"
    spacer
    v-model="isSidebarActiveLocal"
  >
    <div class="mt-6 flex items-center justify-between px-6">
      <!-- <h4>
        {{ Object.entries(this.data).length === 0 ? "ADD NEW" : "UPDATE" }} ITEM
      </h4> -->
      <feather-icon
        icon="XIcon"
        @click.stop="isSidebarActiveLocal = false"
        class="cursor-pointer"
      ></feather-icon>
    </div>

    <!-- <vs-divider class="mb-0"></vs-divider> -->

    <VuePerfectScrollbar
      class="scroll-area--data-list-add-new m-4 "
      :settings="settings"
    >
      <div class="vx-col  w-full mb-4">
        <label class="text-sm opacity-75">{{ $t("MeetingName") }}</label>
        <vs-input class="w-full" v-model="MeetingName" />
      </div>
      <div class="vx-col  w-full mb-4">
        <label class="text-sm opacity-75">{{ $t("IsPublic") }}</label>
        <vs-checkbox class="w-full" v-model="isPublic" />
      </div>

      <div class="vx-col  w-full mb-4">
        <label class="text-sm opacity-75">{{ $t("Date") }}</label>
        <flat-pickr
          class="w-full"
          
          minDate="today"
          :config="configdateTimePicker"
          v-model="date"
          placeholder="Date Time"
        />
      </div>
        <div class="vx-col  w-full mb-4">
        <label class="text-sm opacity-75">{{ $t("Send Automatically") }}</label>
        <vs-checkbox class="w-full" v-model="SendAutomatically" />
      </div>
       <div class="vx-col  w-full mb-4">
        <label class="text-sm opacity-75">{{ $t("Doctor Email") }}</label>
        <vs-input class="w-full" v-model="DoctorEmail" />
      </div>
       <div class="vx-col  w-full mb-4">
        <label class="text-sm opacity-75">{{ $t("Patient Email") }}</label>
        <vs-input class="w-full" v-model="PatientEmail" />
      </div>
    </VuePerfectScrollbar>

    <div class="w-full vx-row items-center p-6" slot="footer">
      <vs-button
        class="mb-4 md:w-auto float-right m-2"
        :disabled="!validateForm"
        @click="Add"
        >{{ $t("Save") }}</vs-button
      >
      <vs-button
        type="border"
        color="danger"
        class="mb-4 md:w-auto float-right m-2"
        @click.stop="isSidebarActiveLocal = false"
        >{{ $t("Cancel") }}</vs-button
      >
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";

// import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
export default {
  components: {
    // vSelect,
    flatPickr,
    VuePerfectScrollbar
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    MeetingDetails: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
       isPublic: false,
      SendAutomatically: false,
      DoctorEmail:null,
      PatientEmail:null,
      date: null,
      selected: [],
      showSearchSidebar: false,
      itemsPerPage: 10,
      configdateTimePicker: {
        enableTime: true,
          minDate:"today"
      },
      isMounted: false,
      first: true,
      // Data Sidebar
      MeetingName: "",
      addNewDataSidebar: false,
      sidebarData: {},
      rowDataForDelete: {},
      settings: {
        // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      }
    };
  },

  watch: {
    isSidebarActive(val) {
      if (!val) {
        return;
      }
    }
  },

  computed: {
  

    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive;
      },
      set(val) {
        if (!val) {
          this.$emit("closeSidebar");
        }
      }
    },
    validateForm() {
      return (
        (!this.errors.any() &&
          this.date != null &&
          this.date != "" &&
          this.MeetingName != "" && 
         (!this.SendAutomatically || this.SendAutomatically==false || (this.SendAutomatically==true  && this.PatientEmail && this.DoctorEmail) )
          ) 
   
      );
    }
  },

  methods: {

    resetValues() {
      this.Model.meetingName = null;
    },

    Add() {
      if (!this.validateForm) {
        return;
      }
      this.$vs.loading();
      debugger;
      var Obj = {
        mettingName: this.MeetingName,
        date: this.date,
        SendAutomatically:this.SendAutomatically,
        DoctorEmail:this.DoctorEmail,
        PatientEmail:this.PatientEmail,
        IsPublic: this.isPublic
      };
      console.log(Obj);
      this.$vs.loading();
      this.$store
        .dispatch("auth/CreateMeeting", Obj)
        .then(response => {
          if (response.status == 200) {
            this.$vs.loading.close();
            location.reload("/");
            this.resetValues();
            this.$emit("closeSidebar");
            window.showSuccess();
          }
        })
        .catch((err) => {
          console.log(err)
          debugger;
          this.$vs.loading.close();
          this.resetValues();
          this.$emit("closeSidebar");
          this.$vs.notify({
            title: this.$t("Error"),
            text: this.$t("showError"),
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "warning"
          });
        });
    }
  },
  created() {

  }
};
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  height: calc(100% - 4.3rem);
}
</style>
